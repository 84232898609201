<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
            type-hidden
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.UY.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.UY.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 4}"
          :lg="{span: 4}"
          :md="{span: 4}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="jubilado"
            :label="$t('forms_flujo.form1_persona.UY.jubilado')"
            :error="errors.get('jubilado')"
          >
            <el-select
              ref="jubilado"
              v-model="formulario1.jubilado"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.jubilado')"
            >
              <el-option
                :key="1"
                :label="$t('forms_flujo.form1_persona.UY.jubilado_si')"
                :value="true"
              />
              <el-col class="hidden-sm-and-up" />
              <el-option
                :key="0"
                :label="$t('forms_flujo.form1_persona.UY.jubilado_no')"
                :value="false"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 8}"
          :lg="{span: 8}"
          :md="{span: 8}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              ref="fecha_ingreso"
              v-model="formulario1.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              :disabled="!(!formulario1.jubilado)"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            />
          </el-form-item>
        </el-col>

        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuota_maxima"
            :label="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
            :error="errors.get('cuota_maxima')"
          >
            <el-input
              ref="cuota_maxima"
              v-model.number="formulario1.cuota_maxima"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form1_persona.UY.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              ref="ingreso_mensual"
              v-model.number="formulario1.ingreso_mensual"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.ingreso_mensual')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="destino_prestamo"
            :label="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
            :error="errors.get('destino_prestamo')"
          >
            <el-input
              ref="destino_prestamo"
              v-model="formulario1.destino_prestamo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <pz-check-form />

      <el-row :gutter="20">
        <el-col
          v-for="(condition) in producto.conditions"
          :key="condition.id"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-between"
        >
          <pz-terms-and-conditions
            v-model="formulario1.conditions[condition.slug]"
            :prop="'condition_'+condition.slug"
            :name="condition.nombre"
          >
            <div v-html="condition.contenido" />
          </pz-terms-and-conditions>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

    import {required, type} from "@/commons/utils/Rules";
    import {getEstadoCivil} from "@/routes/api/resources";
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
    import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
    import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";


    export default {
        name: 'PzPersonForm1Uy',
        components: {PzTermsAndConditions, PzCheckForm, PzFormFlujo, PzIdentityDocument},
        mixins: [PzBaseForm1],
        data() {
            var check_Fecha = (rule, value, callback) => {
                if (this.formulario1.jubilado) {
                    return callback();
                }
                if (!this.formulario1.jubilado && !value) {
                    return callback(new Error(this.$t('rules.incomplete')));
                }
                return callback();
            };

            return {
                reference: 'formulario1',
                marital_status: [],
                formulario1: {

                    telefono: '',
                    estado_civil_id: '',
                    fecha_nacimiento: '',
                    fecha_ingreso: '',
                    cuota_maxima: '',
                    ingreso_mensual: '',
                    destino_prestamo: '',
                    jubilado: null,
                    identity: {document: 1},
                    comprobante_domicilio: null,
                    comprobante_ingresos: null,
                    clearing: null,
                },

                rules_formulario1: {
                    'identity.document': [required('')],
                    'identity.number': [required('')],
                    telefono: [required('')],
                    estado_civil_id: [required('')],
                    fecha_nacimiento: [required('')],
                    fecha_ingreso: [{validator: check_Fecha, trigger: 'blur'}],
                    jubilado: [required('')],
                    cuota_maxima: [required(''),
                        type({field: '', type: 'number'}),],
                    ingreso_mensual: [required(''),
                        type({field: '', type: 'number'}),],
                    destino_prestamo: [required('')],
                },
                pickerOptions1: {
                    disabledDate(time) {
                        return Date.now() < time.getTime();
                    },
                },
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('loader/up', {trigger: this.$options.name})
                let estado = getEstadoCivil().then((res) => {
                    this.marital_status = res.data;
                }).catch(() => {
                    this.marital_status = [];
                });
                return Promise.all([estado]).then(() => {
                }).finally(() => {this.$store.dispatch('loader/down', {trigger: this.$options.name})});
            },
        },


    }
</script>

